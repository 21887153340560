import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValidMailTo } from '@memberspot/shared/util/helper';
import { emailPattern } from '@memberspot/shared/util/regex';
import { Observable, of, timer } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import {
  isValidHttpURL,
  isValidURLWithoutProtocol,
} from './validators/url-validator/is-valid-url';

export interface IGetByUrl {
  getByUrl(url: string): Observable<any[]>;
}

export const CustomValidators = {
  notOnlyWhitespace: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    if (/\S/.test(control.value)) {
      return null;
    } else {
      return { onlyWhitespace: true };
    }
  },

  onlyTrue: (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    return control.value === true ? null : { isNotTrue: true };
  },

  numberValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    const invalid = isNaN(Number(control.value));

    return invalid ? { isNotANumber: true } : null;
  },

  hexColorValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const valid = /^#[0-9A-F]{6,8}$/i.test(control.value);

    return !valid ? { invalidHexColorCode: true } : null;
  },

  positiveNumberZeroValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    if (isNaN(Number(control.value))) {
      return { isNotANumber: true };
    }

    const invalid = Number(control.value) < 0;

    return invalid ? { numberIsNegative: true } : null;
  },

  positiveNumberValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    if (isNaN(Number(control.value))) {
      return { isNotANumber: true };
    }

    const invalid = Number(control.value) < 1;

    return invalid ? { numberIsNegative: true } : null;
  },

  positiveWholeNumberValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const pattern = new RegExp('^[0-9]*$');
    const isPositiveWholeNumber = pattern.test(control.value);

    return isPositiveWholeNumber ? null : { isNotPositiveWholeNumber: true };
  },

  dateInFuture: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    const now = new Date();
    const invalid = now >= control.value;

    return invalid ? { isNotInFuture: true } : null;
  },

  bracketsClosed: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    let bracketCounter = 0;

    for (const letter of control.value) {
      if (letter === '{') {
        bracketCounter++;
      } else if (letter === '}') {
        bracketCounter--;
      }
    }

    return bracketCounter !== 0 ? { bracketsNotMatching: true } : null;
  },

  urlValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    const urlValid =
      control.value.startsWith('https://') ||
      control.value.startsWith('http://');

    return !urlValid ? { urlInvalid: true } : null;
  },

  urlValidatorWithMailTo: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    if (!control || !control.value) {
      return null;
    }

    let urlValid = isValidHttpURL(control.value);

    if (!urlValid) {
      urlValid = isValidMailTo(control.value);
    }

    return !urlValid ? { urlInvalid: true } : null;
  },

  mspotHostValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/;
    const urlValid = pattern.test(control.value);

    return urlValid ? null : { mspotHostInvalid: true };
  },

  hostnameValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const pattern = new RegExp(
      '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$',
    );
    const urlValid = pattern.test(control.value);

    return urlValid ? null : { hostnameInvalid: true };
  },

  urlRegexUmlauteValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const urlValid = isValidHttpURL(control.value);

    return urlValid
      ? null
      : isValidHttpURL(control.value)
        ? { urlInvalidHttp: true }
        : { urlInvalid: true };
  },

  urlRegexValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const pattern = new RegExp(
      '(https?://[\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    );
    const patternHttpOpt = new RegExp(
      '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    );
    const urlValid = pattern.test(control.value);

    return urlValid
      ? null
      : patternHttpOpt.test(control.value)
        ? { urlInvalidHttp: true }
        : { urlInvalid: true };
  },

  urlRegexValidatorWithoutProtocol: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const urlValid = isValidURLWithoutProtocol(control.value, {
      require_protocol: false,
    });

    if (!urlValid) {
      return { urlInvalid: true };
    }

    const url = control.value;
    const isValidWithProtocol = isValidHttpURL(`https://${url}`);

    return isValidWithProtocol ? null : { urlInvalid: true };
  },

  vimeoValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    const urlValid = control.value.startsWith('https://vimeo.com/');

    return !urlValid ? { noVimeoLink: true } : null;
  },

  youtubeValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    const urlValid =
      control.value.startsWith('https://youtube.com/') ||
      control.value.startsWith('https://youtu.be/') ||
      control.value.startsWith('https://www.youtube.com/');

    return !urlValid ? { noYoutubeLink: true } : null;
  },

  youtubeVimeoValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control) {
      return null;
    }

    const urlInValid =
      control.value.startsWith('https://youtube.com') ||
      control.value.startsWith('https://vimeo.com');

    return urlInValid ? { youtubeVimeo: true } : null;
  },

  emailValidator: (
    control: AbstractControl,
  ): { [key: string]: boolean } | null => {
    if (!control || !control.value) {
      return null;
    }

    const email = (control.value as string).replace(/\s/g, '');
    // eslint-disable-next-line no-useless-escape

    const emailValid = emailPattern.test(email);

    return emailValid ? null : { emailInvalid: true };
  },

  cannotContainSpace: (control: AbstractControl): ValidationErrors | null => {
    if (!control) {
      return null;
    }

    if (/\s/.test(control.value)) {
      return {
        containsSpace: true,
      };
    }

    return null;
  },

  createIsEqualToValue: (value: string) => (control: AbstractControl) => {
    const ctrlVal = control.value;

    if (!ctrlVal) {
      return null;
    }

    if (value.toLocaleLowerCase() === ctrlVal.toLocaleLowerCase()) {
      return null;
    }

    return { isNotEqualToValue: true };
  },

  createUrlShouldBeUnique:
    (service: IGetByUrl, oldValue?: string) => (control: AbstractControl) => {
      const url = control.value;

      if (!url) {
        return of(null);
      }

      if (oldValue && url === oldValue) {
        return of({ valueNotChanged: true });
      }

      return timer(500).pipe(
        switchMap(() => service.getByUrl(url)),
        first(),
        map((arr) => (arr.length === 0 ? null : { urlTaken: true })),
      );
    },

  passwordsMatch: (c: AbstractControl): { noPasswordMatch: boolean } | null => {
    if (!c.parent || !c.parent.get('password')) {
      return null;
    }

    if (c.parent.get('password')?.value !== c.value) {
      return { noPasswordMatch: true };
    }

    return null;
  },

  onlyLettersAndNumbers: (
    control: AbstractControl,
  ): ValidationErrors | null => {
    if (!control) {
      return null;
    }

    if (/^[a-z0-9]+$/i.test(control.value)) {
      return null;
    }

    return { containsOtherCharacters: true };
  },

  onlyPhoneNumberCharacters: (
    control: AbstractControl,
  ): ValidationErrors | null => {
    if (!control) {
      return null;
    }

    if (control.value === '' || !control.value) {
      return null;
    }

    if (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g.test(control.value)) {
      return null;
    }

    return { onlyValidPhoneCharacters: true };
  },
};
