/*
options for isURL method

require_protocol - if set as true isURL will return false if protocol is not present in the URL
require_valid_protocol - isURL will check if the URL's protocol is present in the protocols option
protocols - valid protocols can be modified with this option
require_host - if set as false isURL will not check if host is present in the URL
require_port - if set as true isURL will check if port is present in the URL
allow_protocol_relative_urls - if set as true protocol relative URLs will be allowed
validate_length - if set as false isURL will skip string length validation (IE maximum is 2083)

This is the same function as used in the class-validator library
https://github.com/typestack/class-validator/blob/develop/src/decorator/string/IsUrl.ts

It uses the validator.js validation under the hood and the code here is copied from the validator.js library
https://github.com/validatorjs/validator.js/blob/master/src/lib/isURL.js

it uses also this two functions that are also here copied from the validator.js library
https://github.com/validatorjs/validator.js/blob/master/src/lib/isFQDN.js
https://github.com/validatorjs/validator.js/blob/master/src/lib/isIP.js

*/

import isFQDN from './isFQDN';
import { isIP } from './isIP';
import { URLValidatorOptions } from './url-validator-options';

const default_url_options: URLValidatorOptions = {
  protocols: ['http', 'https', 'ftp'],
  require_tld: true,
  require_protocol: false,
  require_host: true,
  require_port: false,
  require_valid_protocol: true,
  allow_underscores: false,
  allow_trailing_dot: false,
  allow_protocol_relative_urls: false,
  allow_fragments: true,
  allow_query_components: true,
  validate_length: true,
};

const wrapped_ipv6 = /^\[([^\]]+)\](?::([0-9]+))?$/;

function isRegExp(obj: any) {
  return Object.prototype.toString.call(obj) === '[object RegExp]';
}

function checkHost(host: string, matches: any[]) {
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];

    if (host === match || (isRegExp(match) && match.test(host))) {
      return true;
    }
  }

  return false;
}

export function isValidURL(url: string, options?: URLValidatorOptions) {
  if (!url || /[\s<>]/.test(url)) {
    return false;
  }

  if (url.indexOf('mailto:') === 0) {
    return false;
  }

  options = { ...default_url_options, ...options };

  if (options.validate_length && url.length >= 2083) {
    return false;
  }

  if (!options.allow_fragments && url.includes('#')) {
    return false;
  }

  if (
    !options.allow_query_components &&
    (url.includes('?') || url.includes('&'))
  ) {
    return false;
  }

  let protocol, auth, host, port, port_str, split, ipv6;

  split = url.split('#');
  url = split.shift() || '';

  split = url.split('?');
  url = split.shift() || '';

  split = url.split('://');

  if (split.length > 1) {
    protocol = split.shift()?.toLowerCase() || '';

    if (
      options.require_valid_protocol &&
      options.protocols.indexOf(protocol) === -1
    ) {
      return false;
    }
  } else if (options.require_protocol) {
    return false;
  } else if (url.slice(0, 2) === '//') {
    if (!options.allow_protocol_relative_urls) {
      return false;
    }

    split[0] = url.slice(2);
  }

  url = split.join('://');

  if (url === '') {
    return false;
  }

  split = url.split('/');
  url = split.shift() || '';

  if (url === '' && !options.require_host) {
    return true;
  }

  split = url.split('@');

  if (split.length > 1) {
    if (options.disallow_auth) {
      return false;
    }

    if (split[0] === '') {
      return false;
    }

    auth = split.shift() || '';

    if (auth.indexOf(':') >= 0 && auth.split(':').length > 2) {
      return false;
    }

    const [user, password] = auth.split(':');

    if (user === '' && password === '') {
      return false;
    }
  }

  const hostname = split.join('@') || '';

  port_str = null;
  ipv6 = null;
  const ipv6_match = hostname.match(wrapped_ipv6);

  if (ipv6_match) {
    host = '';
    ipv6 = ipv6_match[1];
    port_str = ipv6_match[2] || null;
  } else {
    split = hostname.split(':');
    host = split.shift();

    if (split.length) {
      port_str = split.join(':');
    }
  }

  if (port_str !== null && port_str.length > 0) {
    port = parseInt(port_str, 10);

    if (!/^[0-9]+$/.test(port_str) || port <= 0 || port > 65535) {
      return false;
    }
  } else if (options.require_port) {
    return false;
  }

  if (options.host_whitelist) {
    return checkHost(host || '', options.host_whitelist);
  }

  if (host === '' && !options.require_host) {
    return true;
  }

  if (
    !isIP(host || '') &&
    !isFQDN(host || '', options) &&
    (!ipv6 || !isIP(ipv6, 6))
  ) {
    return false;
  }

  host = host || ipv6;

  if (options.host_blacklist && checkHost(host || '', options.host_blacklist)) {
    return false;
  }

  return true;
}

export function isValidHttpURL(url: string, options?: any) {
  return isValidURL(url, { ...options, require_protocol: true });
}

export function isValidURLWithoutProtocol(url: string, options?: any) {
  return isValidURL(url, { ...options, require_protocol: false });
}
